import React from "react"
import { Link } from "gatsby"

export default () => {
  return (
    <section className="relative flex-shrink-0 w-full h-20 px-4 sm:px-8">
      <div className="container flex items-center justify-center w-full h-full mx-auto font-sans">
        <Link to="/">Jeff Barg</Link>
      </div>
    </section>
  )
}
