import React from "react"
import { graphql } from "gatsby"
import rehypeReact from "rehype-react"

import "./markdown.css"
import Photo from "./Photo"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, htmlAst } = markdownRemark

  const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: {
      "rehype-image": Photo,
    },
  }).Compiler

  return (
    <Layout>
      <div className="flex items-start justify-center mx-auto">
        {/* <div className="max-w-md pr-8">
          <p className="text-2xl font-semibold">Table of Contents</p>
          <div
            className="blog-post-content markdown"
            dangerouslySetInnerHTML={{ __html: tableOfContents }}
          />
        </div> */}
        <SEO title={`${frontmatter.title} | Jeff Barg`} />

        <div className="max-w-2xl">
          <article className="mb-2">
            <header className="mb-8">
              <h1 className="text-4xl font-semibold font-heading">
                {frontmatter.title}
              </h1>
              <h2>{frontmatter.date}</h2>
            </header>
            <section className="prose">{renderAst(htmlAst)}</section>
          </article>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      tableOfContents(
        absolute: true
        pathToSlugField: "frontmatter.path"
        maxDepth: 2
      )
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`
