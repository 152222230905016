import React from "react"
import Navbar from "./Navbar"
import Footer from "./Footer"

import "../styles/namefont.css"
import "../styles/base.css"
import "../styles/index.css"

export default ({ children }) => {
  return (
    <div className="flex flex-col h-full">
      <div className="flex-grow">
        <Navbar />
        <div className="w-full p-4 font-sans sm:p-8">{children}</div>
      </div>
      <Footer />
    </div>
  )
}
